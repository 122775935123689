<template>
  <div class="Reviews">
    <div class="container">
      <div class="row">
        <div class="section1">
          <div class="col-lg-6 col-12">
            <div class="left_box">
              <div class="itemLeft">
                <h1 class="">Our Teachers</h1>
                <div class="hr"></div>
                <p>
                  Teaching abroad is not only kind to your bank balance, but also allows you to travel and live new experiences!
                  Don’t take our word for it – have a look at the testimonials from some of our teachers!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="section2 d-flex flex-wrap">
          <div class="col-lg-6 col-12 imgBox" v-for="(item,index) in imgSrc.slice(0, displayCount)" :key="index" @mouseenter="handleMouseOver(index)" @mouseleave="handleMouseOut(index)">
            <img class="mw-100" :src="item.res1" alt="" v-show="item.isSrc">
            <img class="mw-100" :src="item.res2" alt="" v-show="!item.isSrc" >
            <div class="itemBox">
              <h3 style="color:#cb3338;font-weight: bolder">{{item.name}}</h3>
              <p>{{item.content}}</p>
            </div>
          </div>
          <div class="col-12 btnBox" v-show="!this.isMore && imgSrc.length > 4">
            <button @click="getMore">To View More</button>
          </div>
        </div>
      </div>
    </div>
    <div class="section3" v-show="isMore">
      <div class="imgBox">
        <img class="mw-100 d-lg-block d-none" src="../../assets/images/reviews/GTR-slogan-long.png" alt="">
        <img class="mw-100 d-lg-none d-block" src="../../assets/images/reviews/GTR-slogan-short.png" alt="">
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
export default {
  components: {Footer},
  data() {
    return {
         isMore: false,  // 控制是否显示更多
         displayCount: 4, // 控制显示的图片数量
         imgSrc:[
             {
               res1:require("../../assets/images/reviews/n_gtr_Asset 43@2x.png"),
               res2:require("../../assets/images/reviews/n_gtr_Asset 47@2x.png"),
               isSrc:true,
               name:'Ms. Taylor',
               content:
                   "I had a fantastic experience with Global Teacher Recruitment! The entire process was smooth and straightforward from start to finish. " +
                   "Charl was incredibly supportive, providing clear guidance every step of the way. He made the application and placement process easy, " +
                   "ensuring I felt informed and confident throughout. I highly recommend their services to any teacher looking for a hassle-free recruitment experience"
             },
           {
             res1:require("../../assets/images/reviews/n_gtr_Asset 40@2x.png"),
             res2:require("../../assets/images/reviews/n_gtr_Asset 44@2x.png"),
             isSrc:true,
             name:'Mr. Gregg',
             content:
                 "The services that I received from the team, and especially Brenda, at Global Teacher Recruitment were very good and helped me throughout the whole process. " +
                 "Right from the interview, they were very professional and made everything clear from the start in terms of the placement and all the documents that would " +
                 "be needed in order to obtain a working visa for Hong Kong. They, and especially Brenda, were extremely friendly and answered all the questions that " +
                 "I had very clearly. For anyone that is looking to explore a career in teaching abroad, I would highly recommend the team at Global Teacher Recruitment."
           },
           {
             res1:require("../../assets/images/reviews/n_gtr_Asset 42@2x.png"),
             res2:require("../../assets/images/reviews/n_gtr_Asset 46@2x.png"),
             isSrc:true,
             name:'Ms. Alexander',
             content:
                 "Global Teacher Recruitment is an exceptional recruitment agency that truly goes above and beyond to make relocating to a new country seamless and stress-free. " +
                 "From the very first interaction, their team provided clear, detailed information and step-by-step guidance, ensuring every aspect of the move was well-planned " +
                 "and easy to understand.Their professionalism, responsiveness, and genuine care for their candidates make them stand out as top-tier recruiters. " +
                 "Thanks to their support, the transition has been smooth and manageable, allowing me to focus on saying my goodbyes to friends and family. " +
                 "Highly recommended for anyone looking to make an international career move! Thank you Global Teacher Recruitment."
           },
           {
             res1:require("../../assets/images/reviews/n_gtr_Asset 41@2x.png"),
             res2:require("../../assets/images/reviews/n_gtr_Asset 45@2x.png"),
             isSrc:true,
             name:'Ms. Seegar',
             content:
                 "I had an excellent experience with Global Teacher Recruitment. Their team was professional, responsive, and truly invested in finding the right fit for " +
                 "my career goals. They took the time to understand my skills and preferences, providing tailored opportunities that matched my background perfectly. " +
                 "Communication was seamless, and I appreciated their guidance. Thanks to their efforts, I secured a position that I’m genuinely excited about. Highly " +
                 "recommend their services to anyone seeking a new opportunity!"
           },
         ],
      }
  },
  methods: {
    getMore() {
      this.isMore = true;  // 显示更多内容
      this.displayCount = this.imgSrc.length;  // 显示所有教师
    },
    handleMouseOver(index) {
      this.imgSrc[index].isSrc = false; // 鼠标悬停时切换图片
    },
    handleMouseOut(index) {
      this.imgSrc[index].isSrc = true; // 鼠标移出时恢复图片
    },
  }
}
</script>

<style scoped>

</style>